import React, {Component} from 'react';
import FilterSidebar from 'scenes/dashboard/components/FilterSidebar';
import LagPie from './LagPie';
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import connector from './GeneralView.connect';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import {QUALITY_CATEGORIES} from 'scenes/dashboard/Dashboard';

export class GeneralView extends Component {

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        this.props.getGeneralViewData();
    }

    tabChange = ({lag, color}) => event => {
        this.props.setFilterState({
            ...this.props.filterState,
            selectedLags: [lag],
            deviation: color
        });
        this.props.changeDashboardTab(QUALITY_CATEGORIES);
    }

    render() {
        const {generalViewData, selectedProduct} = this.props;
        const {tabChange} = this;
        return (
            <Flex item container column padding={5} style={{height: '100%'}}>
                <FilterSidebar refresh={this.refresh}/>
                <Flex item container style={{backgroundColor: '#f7f7f7', width: '100%', overflowY: 'auto'}}
                      fullWidth column alignItems={'flex-start'}>
                    <Typography style={{fontSize: 18, fontWeight: 'bold', padding: 5, marginLeft: 20, marginTop: 10}}>
                        {selectedProduct.name || ''}
                    </Typography>
                    <Flex item container style={{
                        marginLeft: 10,
                        padding: 10,
                        borderRadius: 16,
                        border: '1px solid #E3E6EC'
                    }} grow={0}>
                        {
                            !_.isEmpty(generalViewData) && (generalViewData || []).map((lagData, index) => (
                                <LagPie key={`lagPie-${index}`}
                                        lagData={lagData}
                                        tabChange={tabChange}
                                />
                            ))
                        }
                        {
                            _.isEmpty(generalViewData) &&
                            <Flex item container center style={{fontSize: 24, fontWeight: 'bold'}}>
                                No available data for current selection
                            </Flex>
                        }
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        GeneralView
    )
);
