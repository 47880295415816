import _ from 'lodash';
import moment from 'moment';

export function stringGet(object, path, defaultValue){
    return _.get(object, path, defaultValue) || '';
}

const filterRoutesBasedOnUser = (allRoutes, user) => {
    if (!window.appConfig.pathsToRole) {
        throw new Error('No roles found in appConfig: pathToRole is missing');
    }
    if (!user) {
        return [];
    } else {
        return allRoutes.filter((currentRoute) => {
            return user.roles.some((userRole) => {
                const pathToRole = window.appConfig.pathsToRole[currentRoute.path];
                return pathToRole && pathToRole.includes(userRole);
            });
        });
    }
};

const checkMobileBrowser = () => {
    let check = false;
    (function (a) {
        // eslint-disable-next-line
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

const ENTER_KEY_CODE =13;
const DATE_FORMAT = 'MMM D';
const DATE_FORMAT_REGEX = /^\d{1,2}[.]\d{1,2}[.]\d{4}$/;
const API_LOCAL_DATE_FORMAT = 'YYYY-MM-DD';
const API_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';
const VIEW_DATE_FORMAT = 'DD.MM.YYYY';

const uuid = accumulator => {
    return accumulator
        // eslint-disable-next-line
        ? (accumulator ^ Math.random() * 16 >> accumulator / 4).toString(16)
        : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);
};

export function triggerNativeDownload(url, name) {
    const link = document.createElement('a');
    link.setAttribute('download', name);
    link.setAttribute('href', url);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export function formattedUserName(user) {
    return user ? _.trim(`${user.givenName || ''} ${user.familyName || ''}`) : '';
}

export function startCase(text) {
    return text && text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
}

export function dueDateProgress(creationDate, dueDate, currentDate) {
    const totalTime = new Date(dueDate).getTime() - new Date(creationDate).getTime();
    const currentTime = new Date(dueDate).getTime() - currentDate.getTime();
    const elapsedTime = totalTime - currentTime;
    return (currentTime > 0) && (totalTime > 0) ? elapsedTime / totalTime * 100 : 100;
}

export function maxDueDate(dueDate) {
    return new Date().getTime() < new Date(dueDate).getTime()
        ? new Date(dueDate)
        : undefined;
}

/**
 * The result is NOT uri encoded (' ' => %20%)
 * @param paramsAsObject Example:
 * {
 *     relatedTo: 'Machine-9',
 *     view: 'Backlog',
 *     toDate: undefined,
 *     fromDate: null,
 *     selectedUserBusinessIds: [12, 23, 56]
 * }
 * @returns {string} suppose the aforementioned example: '?relatedTo=Machine-9&view=Backlog&selectedUserBusinessIds=12,23,56'
 */
export function queryString(paramsAsObject) {
    let queryString = '?';
    for (let key in paramsAsObject) {
        if (paramsAsObject.hasOwnProperty(key)) {
            const value = _.isArray(paramsAsObject[key]) ? paramsAsObject[key].join(',') : paramsAsObject[key];
            if (value) {
                if (queryString === '?') {
                    queryString += `${key}=${value}`
                } else {
                    queryString += `&${key}=${value}`
                }
            }
        }
    }
    return queryString;
}

export function findIndexByBusinessId(array, item) {
    return array.findIndex(itemInArray => itemInArray.businessId === item.businessId);
}

export function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);

    } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        const textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        } catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
        } finally {
            document.body.removeChild(textarea);
        }
    }
}

export function updateOrPushByBusinessId(array, item){
    const index = array.findIndex(itemInArray => itemInArray.businessId === item.businessId);
    if(index !== -1){
        array.splice(index, 1, item);
    } else {
        array.push(item);
    }
}

/**
 * Helper function to figure out what triggered the re-render of the component
 * use it componentDidUpdate
 * @param prevProps
 * @param prevState
 */
export function whyDoesItRerender(prevProps, prevState, props, state){
    Object.entries(props).forEach(([key, val]) =>
        prevProps[key] !== val && console.log(`Prop '${key}' changed: ${JSON.stringify(prevProps[key])} -> ${JSON.stringify(val)}`)
    );
    if (this.state) {
        Object.entries(state).forEach(([key, val]) =>
            prevState[key] !== val && console.log(`State '${key}' changed`)
        );
    }
}

export function joinUrl(base, path) {
    return base.charAt(base.length-1) === '/'
        ? base.slice(0, -1) + path
        : base + path;
}

export function isEmailWellFormed(email) {
    // eslint-disable-next-line
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
}

export function createDelayedAction(callback, delay){
    let timeout = setTimeout(callback, delay);
    return {
        clear: function () {
            clearTimeout(timeout);
        },
        trigger: function () {
            clearTimeout(timeout);
            return callback();
        }
    };
}

export function toBoolean(value) {
    let copy = _.cloneDeep(value);
    if (typeof (copy) === 'string') {
        copy = copy.trim().toLowerCase();
    }
    switch (copy) {
        case true:
        case "true":
        case 1:
        case "1":
            return true;
        default:
            return false;
    }
}

export function utcApiInstant(date){
    return date ? moment.utc(date).format(API_DATE_FORMAT) : null;
}

export function toDate(dateAsInteger){
    return moment(dateAsInteger + '02').format('MMM YY');
}

export function trimLeadingZeros(string=''){
    return string.replace(/^0+/, '');
}

const utils = {
    utcApiInstant,
    filterRoutesBasedOnUser,
    checkMobileBrowser,
    checkBrowser: checkMobileBrowser,
    DATE_FORMAT,
    DATE_FORMAT_REGEX,
    API_LOCAL_DATE_FORMAT,
    VIEW_DATE_FORMAT,
    API_DATE_FORMAT,
    uuid,
    triggerNativeDownload,
    formattedUserName,
    startCase,
    dueDateProgress,
    maxDueDate,
    queryString,
    findIndexByBusinessId,
    copyToClipboard,
    updateOrPushByBusinessId,
    whyDoesItRerender,
    joinUrl,
    ENTER_KEY_CODE,
    isEmailWellFormed,
    createDelayedAction
};

export default utils;
