import React, { Component } from 'react';
import { FormControl, Card, CardContent, Typography, CircularProgress, CardMedia } from "@mui/material";
import { styled } from '@mui/material/styles';
import { transparentBlack054 } from 'components/colors/Colors';
import PaaTextField from 'components/paa-textfield/PaaTextField';
import PaaFilledButton from 'components/button/PaaFilledButton';
import Flex from 'components/grid/Flex';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import EpiLogo from 'assets/img/paa-logo.svg';

const FormCard = styled((props) => <Card {...props} />)(({ theme }) => ({
    borderRadius: "16px",
    boxShadow: 'none',
    padding: '22px'
}));
const FormTitle = styled((props) => <Typography {...props} />)(({ theme }) => ({
    fontFamily: 'Inter',
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '38px',
    marginBottom: '20px'
}));
const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export class ForgotPassword extends Component {
    state = {
        isEmailBeingRequested: false,
        isEmailRequested: false
    };
    requestNewPassword = event => {
        this.setState({
            isEmailBeingRequested: true,
            isEmailRequested: false
        }, () => this.props.requestNewPassword(this.props.userName)
            .then(() => {
                this.setState({
                    isEmailBeingRequested: false,
                    isEmailRequested: true
                });
            })
            .catch((error) => {
                this.setState({
                    warning: '' + _.get(error, 'error.response.data.status'),
                    isEmailBeingRequested: false,
                    isEmailRequested: false
                });
            }));
        event.preventDefault();
    };
    render() {
        const { t: translate, warning, userName, changeState, changeView } = this.props;
        const { isEmailBeingRequested, isEmailRequested } = this.state;
        const { requestNewPassword } = this;
        return (
            <div style={{ width: "100%", height: "100%", display: 'flex' }}>
                <div style={{ width: '100%', maxWidth: '520px', margin: "auto" }}>
                    <FormCard variant="outlined">
                        <CardContent>
                            <form>
                                <CardMedia component="img" image={EpiLogo} alt="logo" style={{ width: 180, margin: "0 auto 30px" }} />
                                <FormTitle component="h3" textAlign="center">{translate('login.forgot-password')}</FormTitle>
                                <Typography style={{ color: transparentBlack054, marginBottom: 30, fontSize: 14, textAlign: "center" }}>
                                    {translate('login.forgot-password-text').replaceAll("'", "")}
                                </Typography>
                                <FormControl margin="normal" required fullWidth>
                                    <PaaTextField
                                        size={'small'}
                                        name={'userName'}
                                        style={{ width: '100%' }}
                                        autoFocus
                                        inputProps={{
                                            autoCapitalize: 'off'
                                        }}
                                        helperText={(warning || '').startsWith('5') ? translate("login.incorrect-username") : ''}
                                        error={(warning || '').startsWith('5')}
                                        type="text"
                                        value={userName}
                                        onChange={changeState}
                                        placeholder={translate('login.forgot-password-email')}
                                    />
                                </FormControl>
                                <Flex item container>
                                    <PaaFilledButton
                                        fullWidth
                                        style={{
                                            marginRight: 10
                                        }}
                                        variant="contained"
                                        onClick={() => changeView('LOGIN')}
                                    >
                                        {translate('global.cancel')}
                                    </PaaFilledButton>
                                    <PaaFilledButton
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={!userName || regex.test(userName) === false}
                                        onClick={isEmailRequested ? () => changeView('LOGIN') : requestNewPassword}
                                    >
                                        {isEmailRequested ? translate('global.back') : translate('login.forgot-password-request')}
                                    </PaaFilledButton>
                                </Flex>
                                {
                                    isEmailBeingRequested &&
                                    <Flex item container style={{ marginTop: 20 }} justifyContent={'center'} alignItems={'center'}>
                                        <CircularProgress />
                                    </Flex>
                                }
                                {
                                    isEmailRequested &&
                                    <Flex item container style={{ marginTop: 20 }}>
                                        <Typography style={{ color: transparentBlack054 }}>
                                            {translate('login.forgot-password-sent')}
                                        </Typography>
                                    </Flex>
                                }
                            </form>
                        </CardContent>
                    </FormCard>
                </div>
            </div>
        );
    }
}
export default withTranslation()(ForgotPassword);
