export default {
    mapStateToProps: (state) => {
        return {
            isSidebarExpanded: state.mainReducer.isSidebarExpanded
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
        };
    }
};
