import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import {isAdmin} from 'domain/User.model';
import InputLabel from 'components/input/InputLabel';
import MonthPicker from 'components/calendar/MonthPicker';
import ButtonSwitch from 'components/switch/ButtonSwitch';
import {withTranslation} from 'react-i18next';
import PaaTextField from 'components/paa-textfield/PaaTextField';
import Autocomplete from 'components/autocomplete/Autocomplete';
import {createFilterOptions} from '@mui/material/Autocomplete';
import {connect} from 'react-redux';
import connector from './Filter.connect';
import {Typography, ListItem, Popper, FormGroup, FormControlLabel, Checkbox} from '@mui/material';
import {QUALITY_CATEGORIES} from 'scenes/dashboard/Dashboard';
import {catskillWhite} from 'components/colors/Colors';
import _ from 'lodash';
import {trimLeadingZeros} from 'utils/Utils';
import DynamicAutocomplete from './DynamicAutocomplete';
import moment from 'moment';

export class FilterSidebar extends Component {

    state = {
        isFresh: true
    }

    selectDynamicFilter = staticKey => (event, value) => {
        const index = (this.props.filterState.dynamicPropertyInputs || [])
            .findIndex(option => option.staticKey === staticKey);
        let newInputs = _.cloneDeep(this.props.filterState.dynamicPropertyInputs || []);
        if (index > -1) {
            newInputs.splice(index, 1, {staticKey, value: value || 'ALL'});
        } else {
            newInputs.push({staticKey, value: value || 'ALL'});
        }

        this.setFilter({
            ...this.props.filterState, dynamicPropertyInputs: newInputs
        });
    }

    selectDeviation = (event, value) => {
        this.setFilter({...this.props.filterState, deviation: value});
    }

    selectProduct = (event, value) => {
        this.setFilter({...this.props.filterState, product: value});
    }

    selectQualityCategory = (event, value) => {
        this.setFilter({...this.props.filterState, qualityCategoryMode: value});
    }

    setFrom = date => {
        this.setFilter({...this.props.filterState, from: moment(date)});
    }

    setTo = date => {
        this.setFilter({...this.props.filterState, to: moment(date)});
    }

    toggleInputPlan = () => {
        this.setFilter({...this.props.filterState, isInputSelected: !this.props.filterState.isInputSelected});
    }

    checkLag = lag => (event, isChecked) => {
        const selectedLags = [...this.props.filterState.selectedLags];
        const index = this.props.filterState.selectedLags.findIndex(i => i === lag);
        if (index > -1) {
            selectedLags.splice(index, 1);
        } else {
            selectedLags.push(lag);
        }

        this.setFilter({...this.props.filterState, selectedLags});
    }

    setFilter = state => {
        this.props.setFilterState(state).then(() => this.props.refresh());
    }

    defaultFilterOptions = createFilterOptions({limit: 20});

    limitOptions = (options, state) => {
        return this.defaultFilterOptions(options, state);
    };

    setToAllWhenEmpty = (name, isDynamic) => event => {
        if (isDynamic) {
            const dynamicValue = this.props.filterState.dynamicPropertyInputs.find(input => input.staticKey === name);
            if (dynamicValue && !dynamicValue.value) {
                this.selectDynamicFilter(name, {event, value: 'ALL'});
            }
        } else {
            if (!this.props.filterState.product) {
                this.selectProduct(event, 'ALL');
            }
        }

    }

    render() {
        const {
            t: translate, user, products, filterState, excludeFromDate, productsFromBackend,
            minMaxDate, tab, lags, deviations, dynamicFilterOptions, qualityCategoryModes
        } = this.props;
        const {
            product, isInputSelected, from, to, qualityCategoryMode, deviation,
            selectedLags, dynamicPropertyInputs
        } = filterState;
        const {
            selectProduct, setFrom, setTo, toggleInputPlan, limitOptions,
            setToAllWhenEmpty, selectDeviation, selectQualityCategory, checkLag, selectDynamicFilter
        } = this;
        const productFromBackend = productsFromBackend.find(i => i.value === product);
        return (
            <Flex item container column style={{
                position: 'fixed',
                backgroundColor: 'white',
                height: '100%',
                top: 0,
                left: 71,
                width: 220,
                maxWidth: 220,
                zIndex: 10,
                borderRight: `1px solid ${catskillWhite}`,
                overflow: 'auto'
            }}>
                <Flex item container grow={0} column style={{padding: '10px 10px'}}>

                    <Flex id={'product'} style={{marginBottom: 10}}>
                        <InputLabel>{translate('global.product')}</InputLabel>
                        <Autocomplete
                            options={products || []}
                            onChange={selectProduct}
                            name={'product'}
                            value={trimLeadingZeros(product) || 'ALL'}
                            isOptionEqualToValue={(option, value) => trimLeadingZeros(option) === trimLeadingZeros(value)}
                            style={{width: '100%'}}
                            onBlur={setToAllWhenEmpty('product')}
                            filterOptions={limitOptions}
                            PopperComponent={({style, ...props}) => (
                                <Popper
                                    {...props}
                                    style={{...style, width: 'unset'}}
                                />
                            )}
                            renderOption={(props, option) => {
                                const productOption = productsFromBackend.find(i => i.value === option);
                                return <ListItem {...props}><Typography>
                                    {trimLeadingZeros(productOption?.value)} - {productOption?.name}
                                </Typography></ListItem>
                            }}
                            renderInput={params => (<PaaTextField
                                {...params}
                                size={'small'}
                                helperText={productFromBackend?.name !== productFromBackend?.value
                                    ? productFromBackend?.name
                                    : ''}
                                fullWidth
                            />)}
                        />
                    </Flex>
                    {
                        dynamicFilterOptions.map(filterOption => (
                            <Flex key={filterOption.staticKey} style={{marginBottom: 10}}>
                                <InputLabel>{translate(`dynamic-key.${filterOption.dynamicKey}`)}</InputLabel>
                                <DynamicAutocomplete {...{
                                    filterOption, dynamicPropertyInputs, selectDynamicFilter, setToAllWhenEmpty,
                                }}/>
                            </Flex>
                        ))
                    }
                    <ButtonSwitch id={'manual-ai-plan'} style={{marginBottom: 10}} {...{
                        disabled: true,
                        isLeftSelected: isInputSelected, toggle: toggleInputPlan,
                        labelRight: translate('global.ai-plan'), labelLeft: translate('global.input-plan'),
                    }}/>
                    <Flex id={'to-from-picker'} item container column grow={0} style={{marginBottom: 10}}>
                        <InputLabel>{translate('global.planning-period')} {translate('global.from')}</InputLabel>
                        {
                            !excludeFromDate &&
                            <>
                                <MonthPicker {...{value: from, onChange: setFrom, ...minMaxDate}}/>
                                <InputLabel>{translate('global.to')}</InputLabel>
                            </>
                        }
                        <MonthPicker {...{value: to, onChange: setTo, ...minMaxDate}}/>
                    </Flex>

                </Flex>
                {
                    tab === QUALITY_CATEGORIES &&
                    <Flex item column container style={{padding: '0px 10px'}}>
                        <Flex id={'divider'} item grow={0} style={{
                            borderTop: 'solid 1px grey', margin: 10
                        }}/>
                        <Flex id={'deviation'} fullWidth style={{marginBottom: 10}}>
                            <InputLabel>{translate('global.deviation')}</InputLabel>
                            <Autocomplete
                                options={deviations || []}
                                onChange={selectDeviation}
                                disableClearable
                                name={'deviation'}
                                disabled={!isAdmin(user)}
                                getOptionLabel={option => {
                                    switch (option) {
                                        case 'RED':
                                            return '>50%'
                                        case 'ORANGE':
                                            return '>20% <50%'
                                        case 'GREEN':
                                            return '<20%'
                                        default:
                                            return ''
                                    }
                                }
                                }
                                value={deviation}
                                style={{width: '100%'}}
                                renderInput={params => (<PaaTextField
                                    {...params}
                                    size={'small'}
                                    fullWidth
                                />)}
                            />
                        </Flex>

                        <Flex fullWidth style={{marginBottom: 10}}>
                            <InputLabel>{translate('global.quality-category')}</InputLabel>
                            <Autocomplete
                                options={qualityCategoryModes || []}
                                onChange={selectQualityCategory}
                                name={'qualityCategoryMode'}
                                disableClearable
                                value={qualityCategoryMode}
                                style={{width: '100%'}}
                                getOptionLabel={option => translate(`dynamic-key.${option}`)}
                                renderInput={params => (<PaaTextField
                                    {...params}
                                    size={'small'}
                                    fullWidth
                                />)}
                            />
                        </Flex>

                        <Flex item grow={0} container column fullWidth style={{marginBottom: 10}}>
                            <InputLabel>{translate('global.lags')}</InputLabel>
                            <FormGroup row>
                                {
                                    lags && lags.map(lagOption => (
                                        <FormControlLabel
                                            style={{margin: 5}}
                                            key={lagOption}
                                            control={<Checkbox checked={!!selectedLags.find(i => i === lagOption)}
                                                               style={{padding: 2}}
                                                               onChange={checkLag(lagOption)}
                                            />}
                                            label={lagOption}
                                            labelPlacement="end"
                                        />
                                    ))
                                }
                            </FormGroup>
                        </Flex>
                    </Flex>
                }
            </Flex>

        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        FilterSidebar
    )
);
