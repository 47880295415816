import React from 'react';
import { styled } from '@mui/material/styles';
import { Autocomplete } from '@mui/material';

export default styled((props) => <Autocomplete {...props} />)(({theme}) => ({
    fontSize: '14px',
    '&.MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
        padding: 0,
    },
    '&.MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input':{
        // padding: 0,
        padding: '0px 4px 0px 6px',
        fontFamily: 'Inter',
    }
}));
