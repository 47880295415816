import React from 'react';
import Flex from 'components/grid/Flex';
import {
    Pie,
    Cell,
    PieChart,
    ResponsiveContainer,
    LineChart,
    CartesianGrid,
    XAxis,
    Tooltip,
    Line
} from 'recharts';
import _ from 'lodash';
import {Typography} from '@mui/material';
import {donutGreen, donutOrange, donutRed, donutGrey, concrete} from 'components/colors/Colors';
import {styled} from '@mui/material/styles';

const DotContainer = styled((props) => <Flex {...props}/>)(({theme}) => ({
    width: 60,
    cursor: 'pointer',
    flex: '0 1 auto',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    padding: 4,
    "&:hover": {
        backgroundColor: concrete
    }
}));

const Dot = props => {
    return <div style={{
        backgroundColor: props.color,
        width: 10,
        height: 10,
        borderRadius: '100%',
        marginRight: 15
    }}/>
}

const LagPie = props => {
    const {lagData, tabChange} = props;
    const percent = Math.max(lagData.averageDeviation, 0);
    const height = 445;
    const donutHeight = 180;
    const width = 330;

    return (
        <Flex item container column style={{
            height, maxWidth: width, width, backgroundColor: 'white', margin: 10, padding: '14px 22px'
        }}>
            <Typography style={{height: 34, margin: 10, fontSize: 24, fontWeight: 'bold'}}>
                Lag {lagData.lag}
            </Typography>
            <Flex item grow={0} container column fullWidth style={{height: donutHeight, width}}>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={donutHeight} height={donutHeight}>
                        <text x={'50%'} y={'50%'} textAnchor="middle" dominantBaseline="middle"
                              style={{fontSize: 34, fontWeight: 'bold'}}>
                            {_.round(percent, 0)}%
                        </text>
                        <Pie
                            isAnimationActive={false}
                            data={[{percent: 100 - percent}, {percent: percent}]}
                            dataKey="percent"
                            innerRadius="80%"
                            outerRadius="100%"
                            fill={percent < 50 ? donutRed : percent < 80 ? donutOrange : donutGreen}
                            startAngle={-270}
                            cornerRadius={10}
                            paddingAngle={0}
                            blendStroke
                        >
                            <Cell fill={donutGrey}/>
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </Flex>
            <Flex item grow={0} container style={{height: 50, marginTop: 10, marginBottom: 10}}
                  justifyContent={'center'}>
                <DotContainer onClick={tabChange({lag: lagData.lag, color: 'GREEN'})}>
                    <Dot color={'green'}/>
                    <Typography style={{fontSize: 14}}>
                        {lagData.greenProductCount}
                    </Typography>
                </DotContainer>
                <DotContainer onClick={tabChange({lag: lagData.lag, color: 'ORANGE'})}>
                    <Dot color={'orange'}/>
                    <Typography style={{fontSize: 14}}>
                        {lagData.orangeProductCount}
                    </Typography>
                </DotContainer>
                <DotContainer onClick={tabChange({lag: lagData.lag, color: 'RED'})}>
                    <Dot color={'red'}/>
                    <Typography style={{fontSize: 14}}>
                        {lagData.redProductCount}
                    </Typography>
                </DotContainer>
            </Flex>
            <Flex item grow={0} container style={{height: 140, width: 365, marginLeft: -15}}>
                {
                    !_.isEmpty(lagData.history) &&
                    <ResponsiveContainer width="99%" height="99%">
                        <LineChart
                            width={295}
                            height={140}
                            data={lagData.history}
                            padding={{
                                top: 5,
                                right: 5,
                                left: 5,
                                bottom: 5,
                            }}
                            margin={{
                                top: 5,
                                right: 25,
                                left: 25,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="x" interval={"preserveStartEnd"}/>
                            <Tooltip formatter={value => `${value} %`}/>
                            <Line type="linear" dataKey={'Plan Accuracy'} stroke={'gray'} dot={false} strokeWidth={2}/>
                        </LineChart>
                    </ResponsiveContainer>
                }
            </Flex>
        </Flex>
    );
}

export default LagPie
