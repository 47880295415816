import React, {Component} from 'react';
import {Tabs, Tab, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import Flex from 'components/grid/Flex';
import Sidebar, {SIDEBAR_EXPANDED_WIDTH, SIDEBAR_SHRANK_WIDTH} from 'components/sidebar/Sidebar';
import {connect} from 'react-redux';
import connector from './Dashboard.connect';
import {withTranslation, Trans} from 'react-i18next';
import {withRouter} from 'utils/Route.utils';
import GeneralView from 'scenes/dashboard/general/GeneralView';
import MonthlyTableView from 'scenes/dashboard/monthly-table/MonthlyTableView';
import TargetMonthView from 'scenes/dashboard/month/TargetMonthView';
import SlantView from 'scenes/dashboard/slant/SlantView';
import QualityCategories from 'scenes/dashboard/quality-categories/QualityCategories';
import {alabasterWhite, slateGrey, htmlBlack} from 'components/colors/Colors';
import EpiErrorAlert from 'components/alert/EpiErrorAlert';

export const GENERAL = 'GENERAL';
export const MONTHLY_TABLE = 'MONTHLY_TABLE';
export const MONTH = 'MONTH';
export const SLANT = 'SLANT';
export const QUALITY_CATEGORIES = 'QUALITY_CATEGORIES';

const PaaTab = styled((props) => <Tab {...props} />)(
    ({theme}) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(14),
        color: slateGrey,
        '&.Mui-selected': {
            color: htmlBlack,
        },
    }),
);

export class Dashboard extends Component {
    state = {
        tab: GENERAL,
    };

    componentDidMount() {
        this.props.setFilterState();
        this.props.getConfiguration();
    }

    handleTabChange = (event, value) => {
        this.changeTab(value);
    };

    changeTab = tab => {
        this.setState({tab});
    }

    render() {
        const {t: translate, isSidebarExpanded} = this.props;
        const {tab} = this.state;
        const {handleTabChange, changeTab} = this;
        return (
            <Flex item container column>
                <Sidebar/>
                <Flex item container
                      style={{
                          marginLeft: isSidebarExpanded ? SIDEBAR_EXPANDED_WIDTH + 220 : SIDEBAR_SHRANK_WIDTH + 220,
                          height: '100vh'
                      }}
                      column>
                    <Flex item={'0 1 auto'} container id={'TABS'} style={{
                        padding: 12, paddingBottom: 0, backgroundColor: alabasterWhite
                    }}>
                        <Flex item container grow={0}>
                            <Typography style={{fontSize: 28, fontWeight: 'bold', marginRight: 20}}>
                                <Trans i18nKey={'global.dashboard'}/>
                            </Typography>
                        </Flex>
                        <Tabs value={tab} onChange={handleTabChange} variant="scrollable"
                              scrollButtons="auto">
                            <PaaTab value={GENERAL} label={translate('global.general-view')}/>
                            <PaaTab value={MONTHLY_TABLE} label={translate('global.list-view')}/>
                            <PaaTab value={MONTH} label={translate('global.month-view')}/>
                            <PaaTab value={SLANT} label={translate('global.slant-view')}/>
                            <PaaTab value={QUALITY_CATEGORIES} label={translate('global.quality-categories')}/>
                        </Tabs>
                    </Flex>
                    {
                        tab === GENERAL &&
                        <GeneralView changeDashboardTab={changeTab}/>
                    }
                    {
                        tab === MONTHLY_TABLE &&
                        <MonthlyTableView changeDashboardTab={changeTab}/>
                    }
                    {
                        tab === MONTH &&
                        <TargetMonthView/>
                    }
                    {
                        tab === SLANT &&
                        <SlantView changeDashboardTab={changeTab}/>
                    }
                    {
                        tab === QUALITY_CATEGORIES &&
                        <QualityCategories />
                    }
                </Flex>
                <EpiErrorAlert/>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        withRouter(Dashboard)
    )
);

