import React, {Component} from 'react';
import {connect} from 'react-redux';
import connector from './QualityCategories.connect';
import {withTranslation} from 'react-i18next';
import FilterSidebar from 'scenes/dashboard/components/FilterSidebar';
import Flex from 'components/grid/Flex';
import QualityHeader from './QualityHeader';
import {QUALITY_CATEGORIES} from 'scenes/dashboard/Dashboard';
import QualityRow from './QualityRow';
import {FixedSizeList} from 'react-window';
import {useResizeDetector} from 'react-resize-detector';
import { Button } from '@mui/material';
import _ from 'lodash';

const AutoSizerList = (props) => {
    const {width, height, ref} = useResizeDetector();
    const {qualityCategories, numberFormatter, formatRow} = props;
    return <div ref={ref} style={{height: '90%', width: '100%'}}>
        <FixedSizeList
            height={height || 300}
            itemCount={qualityCategories.length}
            itemSize={60}
            width={width || 500}
            itemData={{rows: qualityCategories, numberFormatter, formatRow}}
        >
            {QualityRow}
        </FixedSizeList>
    </div>;
};

export class QualityCategories extends Component {

    state = {
        exportDisabled: false
    }

    componentDidMount() {
        this.refresh();
    }

    onExcelExport = () => {
        this.setState({exportDisabled: true});
        const {qualityCategories, i18n, filterState} = this.props
        const numberFormatter = new Intl.NumberFormat(i18n.language === 'de' ? 'de-DE' : 'en-US', {
            maximumFractionDigits: 1
        });
        const qualityCategoriesList = qualityCategories.map(q => this.formatRow(q, numberFormatter));
        const {qualityCategoryMode, planner, product, deviation, from, to} = filterState;
        const qualityCategoriesExportDto = {
            qualityCategoriesList,
            qualityCategoryMode,
            planner,
            product,
            deviation,
            from: from.format('MMMM YYYY'),
            to: to.format('MMMM YYYY')
        }
        this.props.exportQualityCategoriesData(qualityCategoriesExportDto).then(() => {
            this.setState({exportDisabled: false});
        });
    }

    formatRow = (row, numberFormatter) => {

        const averageDeviationPerc = _.round(row.averageDeviation, 0);
        const calc = {
            dynamicProperty: row.dynamicProperty,
            totalActualCount: numberFormatter.format(row.totalActualCount),
            totalPlanCount: numberFormatter.format(row.totalPlanCount),
            totalDeviation: row.totalActualCount
            ? numberFormatter.format(_.round(((row.totalPlanCount - row.totalActualCount) / row.totalActualCount) * 100, 0)) + '%'
            : '-',
            averageDeviationPerc,
            averageDeviationPercStr: numberFormatter.format(_.round(row.averageDeviation, 0)) + '%',
            lag: row.lag
        }
    
        return calc;
    }

    refresh = () => {
        this.props.getQualityCategoriesTable();
    }

    render() {
        const {qualityCategories, i18n, filterState, t: translate} = this.props;
        const numberFormatter = new Intl.NumberFormat(i18n.language === 'de' ? 'de-DE' : 'en-US', {
            maximumFractionDigits: 1
        });
        const {qualityCategoryMode} = filterState;
        const {exportDisabled} = this.state;
        return (
            <>
            
            <Flex item container column style={{height: '100%'}}>
                <FilterSidebar refresh={this.refresh} tab={QUALITY_CATEGORIES}/>
                <Flex item container style={{backgroundColor: '#f7f7f7', height: 'calc(100% - 150px)'}} fullWidth column alignItems={'flex-start'}>
                    <Button disabled={exportDisabled} style={{marginLeft: 20, marginBottom: 10, marginTop: 10}} variant="contained" color="primary" onClick={this.onExcelExport}>Excel Export</Button>
                    <Flex item container style={{backgroundColor: '#f7f7f7', height: '80%'}} fullWidth>
                        <Flex item container style={{
                            backgroundColor: 'white',
                            padding: 10,
                            margin: 10,
                            borderRadius: 16,
                            border: '1px solid #E3E6EC',
                            marginTop: 0
                        }} column>
                            <QualityHeader {...{translate, qualityCategoryMode,
                            noLag: (qualityCategories[0] || {}).lag === 0}}/>
                            <AutoSizerList {...{qualityCategories, numberFormatter, formatRow: this.formatRow}}/>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            </>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        QualityCategories
    )
);
