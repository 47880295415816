import React, {Component} from 'react';
import {
    Snackbar,
    IconButton,
    Alert
} from "@mui/material";
import {withStyles} from "@mui/styles";
import {
    Close as CloseIcon
} from '@mui/icons-material';
const styles = theme => ({
    huge: {
        fontSize: 40
    },
    alertMessageHuge: {
        fontSize: 40,
    },
    alertRoot: {
        width: '70vw',
        alignItems: 'center'
    }
});

export class EpiAlert extends Component {

    render() {
        const {isOpen, close, severity, message, style, huge, classes} = this.props;

        return (
            <Snackbar open={isOpen} autoHideDuration={huge ? 10000 : 6000} onClose={close} style={style} classes={{
                ...(huge && {root: classes.huge})
            }}>
                <Alert severity={severity}
                       classes={{
                           ...(huge && {
                               icon: classes.huge,
                               message: classes.alertMessageHuge,
                               action: classes.huge,
                               root: classes.alertRoot
                           })
                       }}
                       action={
                           <IconButton style={{padding: 3}} onClick={() => close()}>
                               <CloseIcon/>
                           </IconButton>
                       }>
                    {message}
                </Alert>

            </Snackbar>
        );
    }
}

export default withStyles(styles)(EpiAlert);
