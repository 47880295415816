import React, { Component } from 'react';
import Flex from 'components/grid/Flex';
import Sidebar, {SIDEBAR_EXPANDED_WIDTH, SIDEBAR_SHRANK_WIDTH} from 'components/sidebar/Sidebar';
import {connect} from 'react-redux';
import connector from './AiPredictor.connect';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'utils/Route.utils';

export class AiPredictor extends Component {
    render() {
        const {isSidebarExpanded} = this.props;

        return (
            <Flex item container column>
                <Sidebar/>
                <Flex item container
                      style={{marginLeft: isSidebarExpanded ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_SHRANK_WIDTH}} column>
                    Ai Predictor
                </Flex>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        withRouter(AiPredictor)
    )
);
