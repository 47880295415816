import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {
    IconButton,
    Tooltip,
} from '@mui/material';
import {htmlWhite, RoyalBlue, catskillWhite} from 'components/colors/Colors';
import Flex from 'components/grid/Flex';
import {LANDING, REPORTS, AI} from 'routes/routes';
import {Trans, withTranslation} from 'react-i18next';
import Profile from 'components/profile/Profile';
import classNames from 'classnames';
import {withStyles} from "@mui/styles";
import {ReactComponent as PAAIcon} from 'assets/img/dashboard.svg';

export const SIDEBAR_EXPANDED_WIDTH = 200;
export const SIDEBAR_SHRANK_WIDTH = 70;

export const styles = theme => ({
    active: {
        background: `linear-gradient(90deg, white 10%, transparent 0%)`,
    },
    icon: {
        width: 25,
        height: 25,
        color: htmlWhite,
        fill: htmlWhite
    },
    hover: {
        '&:hover': {
            background: `linear-gradient(90deg, #DBBE37 10%, transparent 0%)`,
        }
    },
    paperAnchorDockedLeft: {
        border: 'unset'
    },
    dividerContainer: {
        cursor: 'col-resize',
        transition: 'opacity 200ms ease 0s',
        opacity: 0,
        '&:hover': {
            opacity: 1
        }
    },
    divider: {
        width: 2,
        maxWidth: 2,
        backgroundColor: 'rgb(76, 154, 255)',
        height: '100%'
    },
    expandIcon: {
        transform: 'rotate(90deg)'
    },
    shrinkIcon: {
        transform: 'rotate(-90deg)'
    },
    footerDivider: {
        backgroundColor: catskillWhite,
        height: 2,
        marginLeft: 10
    }
});

const SidebarListItem = (props) => {
    const {classes, route} = props;
    return (
        <Flex item={`0 1 70px`} container justifyContent={'center'}
              alignItems={'center'}
              style={{marginLeft: 1}}
              className={classNames(classes.hover, route.path === window.location.pathname ? classes.active : '')}>
            <Tooltip
                placement="right-start"
                disableFocusListener
                title={<Trans i18nKey={route.subtitle}/>}>
                <IconButton component={Link}
                            style={{padding: 0}}
                            onClick={() => {
                            }}
                            to={route.path}>
                    <route.icon className={classes.icon}/>
                </IconButton>
            </Tooltip>
        </Flex>
    );
};

const Footer = () => {
    return <Flex container direction={'column'} alignItems={'center'}>
        <Profile/>
    </Flex>
};

export class Sidebar extends Component {

    render() {
        const {classes} = this.props;

        return (
            <Flex item container column style={{
                position: 'fixed',
                backgroundColor: RoyalBlue,
                height: '100%',
                top: 0,
                left: 0,
                width: 70,
                maxWidth: 70,
                zIndex: 11,
                borderRight: `1px solid ${catskillWhite}`
            }}>
                <Flex item={'0 1 60px'} container justifyContent={'center'} padding={10}>
                    <PAAIcon style={{border: 'solid 2px white', borderRadius: 6}}/>
                </Flex>
                <SidebarListItem classes={classes} route={LANDING}/>
                {/*<SidebarListItem classes={classes} route={AI}/>*/}
                {/*<SidebarListItem classes={classes} route={REPORTS}/>*/}
                <Flex item={'1'} container justifyContent={'center'} direction={'column'}/>
                <Flex item grow={0} container className={classes.footerDivider}
                      style={{marginRight: 10}}/>
                <Footer/>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(Sidebar));
