import {exportSlantViewData, getSlantTableViewData, setFilterState} from 'scenes/dashboard/Dashboard.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            slantData: state.dashboardReducer.slantData,
            selectedProduct: state.dashboardReducer.selectedProduct || {},
            filterState: state.dashboardReducer.filterState || {}
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            getSlantTableViewData: () => dispatch(getSlantTableViewData()),
            setFilterState: filterState => dispatch(setFilterState(filterState)),
            exportSlantViewData: slantViewExportDto => dispatch(exportSlantViewData(slantViewExportDto))
        };
    }
};
