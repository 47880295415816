import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import FilterSidebar from 'scenes/dashboard/components/FilterSidebar';
import {
    ComposedChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Line,
    ReferenceLine
} from 'recharts';
import {connect} from 'react-redux';
import connector from './TargetMonthView.connect';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';
import {Button, Typography, Checkbox} from '@mui/material';

class CustomizedLabel extends Component {
    render() {
        const {x, y = 0, value, fill} = this.props;
        const size = 10;
        const length = value === 'n.a.' ? 0 : ('' + (value || '')).length;
        let rectWidth = Math.max(35, length * size);
        let rectX;
        let textX;
        switch (length) {
            case 0:
            case 1: {
                rectX = x;
                textX = x + 13;
                break;
            }
            case 2: {
                rectX = x;
                textX = x + 8;
                break;
            }
            case 3: {
                textX = x + 5;
                rectX = x;
                break;
            }
            default: {
                rectX = x - (length * 2);
                textX = rectX + 5;
            }
        }

        return (
            <>
                {((value !== 'n.a.') && (value || value === 0)) && (
                    <>
                        <rect x={rectX} y={y - 24} rx={10} width={rectWidth} height="20" fill="white"
                              stroke="#e5e5e5" strokeWidth="0.3%" opacity={0.7}/>
                        <text x={textX} y={y} dy={-10} fill={fill}
                              style={{fontSize: 13, fontWeight: 'bold'}}>{value}</text>
                    </>
                )}
                {(value === 'n.a.') && (
                    <>
                        <text x={x + 10} y={"100%"} dy={-60} fill="#2e75b6" style={{fontSize: 13, fontWeight: 'bold'}}>
                            n.a.
                        </text>
                        <text x={x + 10} y={"100%"} dy={-40} fill="#ff7a1c" style={{fontSize: 13, fontWeight: 'bold'}}>
                            n.a.
                        </text>
                    </>
                )}
            </>
        );
    }
}

export class TargetMonthView extends Component {

    state = {
        isReferenceOn: true,
        exportDisabled: false
    }

    toggleReference = () => {
        this.setState({
            isReferenceOn: !this.state.isReferenceOn
        })
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        this.props.getTargetMonthViewData();
    }

    onExcelExport = () => {
        this.setState({exportDisabled: true});
        const {targetMonthData = [], filterState, selectedProduct} = this.props;
        const {productFamily, planner, from, to} = filterState;

        const targetMonthDataWithFilter = {
            targetMonthData,
            productFamily,
            product: selectedProduct.name,
            planner,
            from: from.format('MMMM YYYY'),
            to: to.format('MMMM YYYY')
        }
        this.props.exportTargetMonthViewData(targetMonthDataWithFilter).then(() => {
            this.setState({exportDisabled: false});
        });
    }

    render() {
        const {targetMonthData = [], filterState, selectedProduct} = this.props;
        const {isReferenceOn, exportDisabled} = this.state;
        const {toggleReference} = this;
        const title = filterState.product !== 'ALL'
            ? selectedProduct.name || ''
            : filterState.productFamily !== 'ALL'
                ? filterState.productFamily
                : filterState.planner;
        const domainMax = _.maxBy(targetMonthData, 'plan');

        return (
            <Flex item container column style={{height: '100%'}}>
                <FilterSidebar refresh={this.refresh} excludeFromDate={true}/>
                <Flex item container style={{backgroundColor: '#f7f7f7', height: 'calc(100% - 150px)'}} fullWidth column
                      alignItems={'flex-start'}>
                    <Typography style={{fontSize: 18, fontWeight: 'bold', padding: 5, marginLeft: 20, marginTop: 10}}>
                        {title}
                    </Typography>
                    <Button disabled={exportDisabled} style={{marginLeft: 20, marginBottom: 10, marginTop: 10}} variant="contained"
                            color="primary" onClick={this.onExcelExport}>Excel Export</Button>
                    <Flex item container style={{
                        backgroundColor: 'white',
                        marginLeft: 10,
                        padding: 10,
                        borderRadius: 16,
                        border: '1px solid #E3E6EC',
                        maxWidth: 1000,
                        maxHeight: 450,
                        width: '97%',
                        height: 'calc(100% - 50px)%',
                        marginBottom: 30,
                        marginTop: 0
                    }}>
                        {/*NOTE LM: width="99%" height="99%" is MUST if you set it to static values OR 100% it will break*/}
                        <ResponsiveContainer width="99%" height="99%">
                            <ComposedChart data={targetMonthData}>
                                <Legend align="center" verticalAlign="top" height={50} payload={[{
                                    type: 'plainline',
                                    value: 'Plan',
                                    color: "#ff7a1c",
                                    payload: {strokeDasharray: "0 0"}
                                }, {
                                    type: 'plainline',
                                    value: 'IST',
                                    color: "#2e75b6",
                                    payload: {strokeDasharray: "0 0"}
                                }]}
                                        formatter={(v, e, i) => v === 'IST'
                                            ? <span>
                                                <span
                                                    style={{
                                                        fontSize: 13,
                                                        fontWeight: 'bold',
                                                        color: "#515151"
                                                    }}>{v}</span>
                                                <Checkbox style={{padding: 1}} checked={isReferenceOn} onClick={toggleReference}/>
                                            </span>
                                            : <span
                                                style={{
                                                    fontSize: 13,
                                                    fontWeight: 'bold',
                                                    color: "#515151"
                                                }}>{v}</span>
                                        }/>
                                <CartesianGrid vertical={false}/>
                                <YAxis tickCount={8} type="number" axisLine={false} tickLine={false}
                                       domain={[0, domainMax]} width={70}/>
                                <XAxis dataKey="date" tickLine={false} padding={{left: 20}}/>
                                <Tooltip/>
                                {
                                    isReferenceOn &&
                                    <Line dot={false} activeDot={false} dataKey="actual" stroke="#5c9cd0"
                                          strokeWidth={3}
                                          style={{filter: `drop-shadow( 3px 3px 2px rgba(0, 0, 0, .4))`}}/>
                                }

                                <ReferenceLine y={(targetMonthData[targetMonthData.length - 1] || {}).actual}
                                               stroke="#2e75b6" strokeDasharray="3 3"/>
                                <Bar dataKey="plan" barSize={35} label={<CustomizedLabel/>} isAnimationActive={false}>
                                    {targetMonthData.map((e, i) => {
                                        return <Cell fill={i === targetMonthData.length - 1 ? "#2e75b6" : "#ff7a1c"}
                                                     key={i}/>
                                    })}
                                </Bar>

                            </ComposedChart>
                        </ResponsiveContainer>
                    </Flex>

                </Flex>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        TargetMonthView
    )
);
