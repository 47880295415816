import React, {Component} from 'react';
import {
    Avatar,
    Tooltip
} from "@mui/material";
import {withStyles} from "@mui/styles";
import _ from 'lodash';
import {getNameInitials} from "domain/User.model";
import {connect} from 'react-redux';
import connector from './HmoAvatar.connect';
import utils from 'utils/Utils';

export const AVATAR_STORE = [];

const styles = theme => ({
    avatarRoot: {
        width: 30,
        height: 30
    },
    smallRoot: {
        width: 20,
        height: 20
    },
    avatarTooltipLabel: {
        fontSize: 14
    },
});

export class HmoAvatar extends Component {

    state = {
        objectUrl: ''
    }

    componentDidMount() {
        const downloadUrl = _.get(this.props.user, 'avatarDownloadUrl') || _.get(this.props.user, 'avatar.downloadUrl');
        this.mountPicture(downloadUrl);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const downloadUrl = _.get(this.props.user, 'avatarDownloadUrl') || _.get(this.props.user, 'avatar.downloadUrl');
        const previousDownloadUrl = _.get(prevProps.user, 'avatarDownloadUrl') || _.get(prevProps.user, 'avatar.downloadUrl');
        if (downloadUrl && (downloadUrl !== previousDownloadUrl)) {
            this.mountPicture(downloadUrl);
        }
    }

    mountPicture(downloadUrl) {
        const objectUrl = (AVATAR_STORE.find(item => item.downloadUrl === downloadUrl) || {}).objectUrl;
        if (objectUrl) {
            this.setState({objectUrl});
        } else if (downloadUrl) {
            this.mountNewPicture(downloadUrl);
        }
    }

    mountNewPicture(downloadUrl) {
        this.props.downloadFileWithAuthentication(downloadUrl).then(response => {
            const objectUrl = URL.createObjectURL(response.payload.data);
            AVATAR_STORE.push({downloadUrl, objectUrl})
            this.setState({objectUrl});
        });
    }

    render() {
        const {classes, user, style, downloadFileWithAuthentication, placement, small, ...other} = this.props;
        const {objectUrl} = this.state;
        const downloadUrl = _.get(user, 'avatarDownloadUrl') || _.get(user, 'avatar.downloadUrl');
        const nameInitials = getNameInitials(user);

        return (
            <Tooltip
                title={utils.formattedUserName(user)}
                placement={placement || 'bottom-start'}
                classes={{
                    tooltip: classes.avatarTooltipLabel
                }}
            >
                <div>
                    {
                        objectUrl
                            ?
                            <Avatar id={`HmoAvatar-${downloadUrl}`}
                                    classes={{root: small ? classes.smallRoot : classes.avatarRoot}}
                                    src={objectUrl}
                                    style={style}
                                    {...other}
                            />
                            :
                            <Avatar
                                id={`HmoAvatar-${downloadUrl}`}
                                classes={{root: small ? classes.smallRoot : classes.avatarRoot}}
                                style={{backgroundColor: 'grey', fontSize: 15, ...style}}
                                {...other}
                            >
                                {nameInitials}
                            </Avatar>
                    }
                </div>
            </Tooltip>
        )
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(HmoAvatar));
