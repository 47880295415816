import React from 'react';
import PaaTextField from 'components/paa-textfield/PaaTextField';
import Autocomplete from 'components/autocomplete/Autocomplete';
import {Typography, ListItem, Popper} from '@mui/material';
import {trimLeadingZeros} from 'utils/Utils';
import {createFilterOptions} from '@mui/material/Autocomplete';

const defaultFilterOptions = createFilterOptions({limit: 20});

function limitOptions(options, state) {
    return defaultFilterOptions(options, state);
}

export default function DynamicAutocomplete({
                                                filterOption, selectDynamicFilter, setToAllWhenEmpty,
                                                dynamicPropertyInputs
                                            }) {

    const options = filterOption.values.map(v => v.value) || [];
    const dynamicProperty = dynamicPropertyInputs.find(o => o.staticKey === filterOption.staticKey) || {};
    const selectedOption = filterOption.values.find(o=> o.value === dynamicProperty.value);
    return <Autocomplete
        options={options}
        onChange={selectDynamicFilter(filterOption.staticKey)}
        name={filterOption.staticKey}
        isOptionEqualToValue={(option, value) =>
            trimLeadingZeros(option) === trimLeadingZeros(value)}
        onBlur={setToAllWhenEmpty(filterOption.staticKey, true)}
        value={trimLeadingZeros(dynamicProperty.value) || 'ALL'}
        style={{width: '100%'}}
        filterOptions={limitOptions}
        PopperComponent={({style, ...props}) => (
            <Popper
                {...props}
                style={{...style, width: 'unset'}}
            />
        )}
        renderOption={(props, option) => {
            const namedOption = filterOption.values.find(o => o.value === option);
            return <ListItem key={option} {...props}>
                <Typography>
                    {trimLeadingZeros(option)}{namedOption?.name !== option
                    ? ` - ${namedOption?.name}`
                    : ''}
                </Typography>
            </ListItem>
        }}
        renderInput={params => (<PaaTextField
            {...params}
            size={'small'}
            helperText={selectedOption?.name !== selectedOption?.value ? selectedOption?.name : ''}
            fullWidth
        />)}
    />
}
