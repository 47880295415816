import * as actionTypes from './Dashboard.action.types';
import moment from 'moment';
import utils from 'utils/Utils';

/**
 * expected to return {planners: [], productFamilies: [], products: []}
 */
export function setFilterState(filterState) {
    return (dispatch, getState) => {
        const safeFilterState = mapFilterState(filterState || getState().dashboardReducer.filterState);
        return dispatch({
            type: actionTypes.SET_FILTER_STATE,
            data: {filterState: safeFilterState},
            payload: {
                client: 'api',
                request: {
                    url: '/set-filters',
                    method: 'post',
                    data: {
                        ...safeFilterState,
                        to: moment(safeFilterState.to).utc().format(utils.API_DATE_FORMAT),
                        from: moment(safeFilterState.from).utc().format(utils.API_DATE_FORMAT)
                    }
                }
            }
        })
    }
}

export function getConfiguration() {
    return {
        type: actionTypes.GET_CONFIGURATION,
        payload: {
            client: 'api',
            request: {
                url: '/configuration',
                method: 'get',
            }
        }
    }
}

function mapFilterState(filterState) {
    return {
        ...filterState,
        // planner: filterState.planner ? filterState.planner : 'ALL',
        // productFamily: filterState.productFamily ? filterState.productFamily : 'ALL',
        product: filterState.product ? filterState.product : 'ALL',
    }
}

export function getGeneralViewData() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.GET_GENERAL_VIEW_DATA,
            data: {filterState: getState().dashboardReducer.filterState},
            payload: {
                client: 'api',
                request: {
                    url: '/general-view',
                    method: 'post',
                    data: {
                        view: 'GENERAL',
                        ...getState().dashboardReducer.filterState,
                        to: moment(getState().dashboardReducer.filterState.to).utc().format(utils.API_DATE_FORMAT),
                        from: moment(getState().dashboardReducer.filterState.from).utc().format(utils.API_DATE_FORMAT)
                    }
                }
            }
        })
    }
}

export function getMonthlyTableViewData() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.GET_MONTHLY_TABLE_VIEW_DATA,
            data: {filterState: getState().dashboardReducer.filterState},
            payload: {
                client: 'api',
                request: {
                    url: '/monthly-table-view',
                    method: 'post',
                    data: {
                        view: 'MONTHLY_TABLE',
                        ...getState().dashboardReducer.filterState,
                        to: moment(getState().dashboardReducer.filterState.to).utc().format(utils.API_DATE_FORMAT),
                        from: moment(getState().dashboardReducer.filterState.from).utc().format(utils.API_DATE_FORMAT)
                    }
                }
            }
        })
    }
}

export function getSlantTableViewData() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.GET_SLANT_TABLE_VIEW_DATA,
            data: {filterState: getState().dashboardReducer.filterState},
            payload: {
                client: 'api',
                request: {
                    url: '/slant-table-view',
                    method: 'post',
                    data: {
                        view: 'SLANT',
                        ...getState().dashboardReducer.filterState,
                        to: moment(getState().dashboardReducer.filterState.to).utc().format(utils.API_DATE_FORMAT),
                        from: moment(getState().dashboardReducer.filterState.from).utc().format(utils.API_DATE_FORMAT)
                    }
                }
            }
        })
    }
}

export function getQualityCategoriesTable() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.GET_QUALITY_CATEGORIES_TABLE,
            data: {filterState: getState().dashboardReducer.filterState},
            payload: {
                client: 'api',
                request: {
                    url: '/quality-categories-view',
                    method: 'post',
                    data: {
                        view: 'QUALITY_CATEGORIES',
                        ...getState().dashboardReducer.filterState,
                        to: moment(getState().dashboardReducer.filterState.to).utc().format(utils.API_DATE_FORMAT),
                        from: moment(getState().dashboardReducer.filterState.from).utc().format(utils.API_DATE_FORMAT)
                    }
                }
            }
        })
    }
}

export function getTargetMonthViewData() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.GET_TARGET_MONTH_VIEW_DATA,
            data: {filterState: getState().dashboardReducer.filterState},
            payload: {
                client: 'api',
                request: {
                    url: '/target-month-view',
                    method: 'post',
                    data: {
                        view: 'TARGET_MONTH',
                        ...getState().dashboardReducer.filterState,
                        from: moment(getState().dashboardReducer.filterState.to).subtract(7, 'month').utc().format(utils.API_DATE_FORMAT),
                        to: moment(getState().dashboardReducer.filterState.to).utc().format(utils.API_DATE_FORMAT),
                    }
                }
            }
        })
    }
}

export function exportTargetMonthViewData(targetMonthDataWithFilter) {
    return async dispatch => {
        const downloadExcel = await dispatch(genericExcelDownload(
            targetMonthDataWithFilter,
            'export-target-month-view',
            actionTypes.EXPORT_TARGET_MONTH_VIEW_DATA)
        );

        genericDownloadFile(downloadExcel);

    }
}

export function exportQualityCategoriesData(qualityCategoriesExportDto) {
    return async dispatch => {
        const downloadExcel = await dispatch(genericExcelDownload(
            qualityCategoriesExportDto,
            'export-quality-categories',
            actionTypes.EXPORT_QUALITY_CATEGORIES_DATA)
        );

        genericDownloadFile(downloadExcel);
    }
}

export function exportSlantViewData(slantViewExportDto) {
    return async dispatch => {
        const downloadExcel = await dispatch(genericExcelDownload(
            slantViewExportDto,
            'export-slant-view',
            actionTypes.EXPORT_SLANT_VIEW)
        );

        genericDownloadFile(downloadExcel);

    }
}

export function exportListViewData(listViewData) {
    return async dispatch => {
        const downloadExcel = await dispatch(genericExcelDownload(
            listViewData,
            'export-list-view',
            actionTypes.EXPORT_LIST_VIEW)
        );

        genericDownloadFile(downloadExcel);
    }
}

const genericExcelDownload = (data, url, type) => {
    return {
        type,
        payload: {
            client: 'api',
            request: {
                url: `/export/${url}`,
                method: 'post',
                data,
                responseType: 'blob',
            }
        }
    }
}

function genericDownloadFile(downloadFile) {

    const downloadAllData = downloadFile.payload.data
    const filename = downloadFile.payload.headers["content-disposition"].split('"')[1];

    let url = window.URL.createObjectURL(new Blob([downloadAllData], {type: "application/octet-stream"}));
    utils.triggerNativeDownload(url, filename);
}
