import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import _ from 'lodash';
import {Typography} from '@mui/material';
import {HeaderTitle} from 'scenes/dashboard/monthly-table/MonthlyTableView';
import {toDate} from 'utils/Utils';
import {withTranslation} from 'react-i18next';
import {styled} from '@mui/material/styles';

export const CellText = styled((props) => <Typography {...props} />)(({theme}) => ({
    fontFamily: 'Inter',
    fontSize: '13px',
    flex: '1 1 auto',
    height: 20,
    textAlign: 'center',
    fontWeight: 'bold',
    minHeight: 20,
}));

export const Cell = (props) => {
    let accuracy, color;
    if (props.row){
        accuracy = 100 - props.row.absoluteDeviationPercent;
        color = accuracy >= 80 ? 'grey'
            : accuracy >= 50 ? 'orange'
                : 'red';
    }
    return (
        <Flex item container grow={0} center
              style={{
                  ...props.style,
                  margin: 2,
                  padding: 5,
                  backgroundColor: props.children ? '#f9fafc' : 'white',
                  height: 30,
                  minHeight: 30,
                  maxHeight: 30
              }}>
            <CellText style={{...(props.row && {color})}}>{props.children}</CellText>
        </Flex>
    );
};

export class TargetMonthColumn extends Component {
    render() {
        const {column, planDates, i18n, onMouseOver, onMouseOut, onColumnClick, isHovered} = this.props;
        const numberFormatter = new Intl.NumberFormat(i18n.language === 'de' ? 'de-DE' : 'en-US', {
            maximumFractionDigits: 1
        });
        const onMouseOverEvent = () => onMouseOver(column.targetMonth);
        const onClickEvent = () => onColumnClick(column.targetMonth);
        return (
            <Flex item={'0 1 auto'} container column style={{
                width: 110,
                cursor: 'pointer',
                borderWidth: 2,
                borderStyle: 'solid',
                borderTopColor: 'white',
                borderRightColor: isHovered ? 'blue' : 'white',
                borderBottomColor: 'white',
                borderLeftColor: isHovered ? 'blue' : 'white',
            }} onMouseOver={onMouseOverEvent}
                  onMouseOut={onMouseOut} onClick={onClickEvent}>
                <Flex item container center grow={0}
                      style={{margin: 2, padding: 5, backgroundColor: '#dfe3fc', height: 30}}>
                    <Typography style={{color: 'black', fontWeight: 'bold'}}>
                        {toDate(column.targetMonth)}
                    </Typography>
                </Flex>
                {
                    !_.isEmpty(column.rows)
                    && planDates.map((planDate, rowIndex) => {
                        const row = column.rows.find(i => i.planDate === planDate);
                        return <Cell row={row} key={`planCountRow-${rowIndex}`}>
                            {row ? numberFormatter.format(row.planCount) : ''}
                        </Cell>
                    })
                }
                <Flex item container center grow={0}
                      style={{margin: 2, padding: 5, backgroundColor: '#f9fafc', height: 30}}>
                    <HeaderTitle
                        style={{color: 'blue', flex: 'unset'}}>{column.actualCount}</HeaderTitle>
                </Flex>
            </Flex>
        );
    }
}

export default withTranslation()(TargetMonthColumn);
