import {setFilterState, getConfiguration} from './Dashboard.action'

export default {
    mapStateToProps: (state) => {
        return {
            isSidebarExpanded: state.mainReducer.isSidebarExpanded
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            setFilterState: () => dispatch(setFilterState()),
            getConfiguration: () => dispatch(getConfiguration()),
        };
    }
};
