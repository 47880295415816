import React from 'react';
import Flex from 'components/grid/Flex';
import {Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {toDate} from 'utils/Utils';

const MonthCell = styled((props) => <Typography {...props} />)(({theme}) => ({
    fontFamily: 'Inter',
    flex: '1 1 auto',
    fontSize: '16px',
    maxWidth: '50%',
    fontWeight: '400',
    lineHeight: '20px',
    color: "#808191",
    textTransform: 'capitalize'
}));
const ActualCell = styled((props) => <Typography {...props} />)(({theme}) => ({
    fontFamily: 'Inter',
    flex: '1 1 auto',
    fontWeight: 600,
    maxWidth: '50%',
    fontSize: 16,
    color: '#11142D'
}));

export default function ActualRow(props) {
    const {month, onMouseOver, onMouseOut, isHovered, onRowClick, numberFormatter} = props;
    const onMouseOverEvent = () => onMouseOver(month.targetMonth);
    const onClickEvent = () => onRowClick(month.targetMonth);

    return (
        <Flex item container grow={0} center onMouseOver={onMouseOverEvent} onMouseOut={onMouseOut}
              onClick={onClickEvent}
              style={{
                  cursor: 'pointer',
                  paddingRight: 12,
                  margin: '5px -7px 5px 5px',
                  borderWidth: 2,
                  borderStyle: 'solid',
                  borderTopColor: isHovered ? 'blue' : 'white',
                  borderRightColor: 'rgba(0,0,0,0)',
                  borderBottomColor: isHovered ? 'blue' : 'white',
                  borderLeftColor: isHovered ? 'blue' : 'white',
              }}>
            <Flex item container center onMouseOver={onMouseOverEvent} onMouseOut={onMouseOut}
                  onClick={onClickEvent}
                  style={{
                      padding: 5,
                      backgroundColor: '#f9fafc',
                      height: 50
                  }}>
                <MonthCell style={{paddingLeft: 10}}>{toDate(month.targetMonth)}</MonthCell>
                <ActualCell>{numberFormatter.format(month.sumActual)}</ActualCell>
            </Flex>
        </Flex>
    );
}
