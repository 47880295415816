import {exportTargetMonthViewData, getTargetMonthViewData} from 'scenes/dashboard/Dashboard.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            targetMonthData: state.dashboardReducer.targetMonthData,
            selectedProduct: state.dashboardReducer.selectedProduct || {},
            filterState: state.dashboardReducer.filterState || {},
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            getTargetMonthViewData:() => dispatch(getTargetMonthViewData()),
            exportTargetMonthViewData:(targetMonthData) => dispatch(exportTargetMonthViewData(targetMonthData)),
        };
    }
};
