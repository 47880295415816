import _ from 'lodash';
import {ReactComponent as DashboardIcon} from 'assets/img/carbon_dashboard.svg';
import {ReactComponent as ReportIcon} from 'assets/img/carbon_report.svg';
import {ReactComponent as AiIcon} from 'assets/img/big data.svg';

const routes = {
    LANDING: {
        path: '/',
        icon: DashboardIcon,
        subtitle: 'global.dashboard'
    },
    AI: {
        path: '/ai',
        icon: AiIcon,
        subtitle: 'global.ai-predictor'
    },
    REPORTS: {
        path: '/reports',
        icon: ReportIcon,
        subtitle: 'global.reports'
    },
    LOGOUT: {
        path: '/logout',
    },
    RESET_PASSWORD: {
        path: '/password-reset/:token',
    },
    REGISTRATION: {
        path: '/user/:userId/confirmation',
    }
};

export const LANDING = routes.LANDING;
export const AI = routes.AI;
export const REPORTS = routes.REPORTS;
export const LOGOUT = routes.LOGOUT;
export const RESET_PASSWORD = routes.RESET_PASSWORD;
export const REGISTRATION = routes.REGISTRATION;

export default Object.values(routes);

export function routeTo(path, props) {
    const prevPath = _.get(props, 'location')
        ? _.get(props, 'location.pathname', '') + _.get(props, 'location.search', '')
        : undefined;
    props.history.push(path, {prevPath});
}

export function prevPath(props) {
    return _.get(props, 'location.pathname', '') + _.get(props, 'location.search', '');
}

export function newTab(path) {
    window.open(window.location.origin + path, '_blank');
}
