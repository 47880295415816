import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import connector from './SlantTableView.connect';
import {withTranslation} from 'react-i18next';
import FilterSidebar from 'scenes/dashboard/components/FilterSidebar';
import Flex from 'components/grid/Flex';
import _ from 'lodash';
import {Button, Typography} from '@mui/material';
import {HeaderTitle} from 'scenes/dashboard/monthly-table/MonthlyTableView';
import moment from 'moment';
import {toDate} from 'utils/Utils';
import TargetMonthColumn, {Cell} from './TargetMonthColumn';

export class SlantView extends Component {
    state = {
        targetMonth: '',
        exportDisabled: false
    }

    componentDidMount() {
        this.refresh();
    }

    onMouseOver = targetMonth => {
        this.setState({targetMonth});
    }

    onColumnClick = targetMonth => {
        this.props.setFilterState({
            ...this.props.filterState,
            to: moment(targetMonth + '02'),
            from: moment(targetMonth + '02').subtract(7, 'month')
        });
        this.props.changeDashboardTab('MONTH');
    }

    onMouseOut = () => {
        this.setState({targetMonth: ''});
    }

    refresh = () => {
        if(!_.isEmpty(this.props.filterState.product) && this.props.filterState.product !== 'ALL'){
            this.props.getSlantTableViewData();
        }
    }

    onExcelExport = () => {
        this.setState({exportDisabled: true});
        const {slantData, filterState} = this.props;
        const {planner, productFamily, product, from, to } = filterState;


        const data = {
            slantData,
            planner,
            productFamily,
            product,
            from: from.format('MMMM YYYY'),
            to: to.format('MMMM YYYY')
        }

        this.props.exportSlantViewData(data).then(() => {
            this.setState({exportDisabled: false});
        });
    }

    render() {
        const {slantData, t: translate, selectedProduct} = this.props;
        const {targetMonth, exportDisabled} = this.state;
        const {onMouseOver, onMouseOut, onColumnClick} = this;

        const isProductSelected = !_.isEmpty(selectedProduct);
        return (
            <Flex item container column style={{height: '100%'}}>
                <FilterSidebar refresh={this.refresh}/>
                <Flex item container style={{backgroundColor: '#f7f7f7'}} fullWidth column alignItems={'flex-start'}>
                    {
                        !isProductSelected &&
                        <Flex item grow={0} fullWidth container center style={{fontSize: 24, marginTop: 40, fontWeight: 'bold'}}>
                            Please select a product for this view
                        </Flex>
                    }
                    {
                        isProductSelected &&
                        <>
                            <Typography style={{fontSize: 18, fontWeight: 'bold', padding: 5, marginLeft: 20, marginTop: 10}}>
                                {selectedProduct.name || ''}
                            </Typography>
                            <Button disabled={exportDisabled} style={{marginLeft: 20, marginBottom: 10, marginTop: 10}} variant="contained" color="primary" onClick={this.onExcelExport}>Excel Export</Button>
                            <Flex item container style={{
                                backgroundColor: 'white',
                                marginLeft: 10,
                                padding: 10,
                                borderRadius: 16,
                                border: '1px solid #E3E6EC'
                            }} grow={0}>
                                <Flex item={'0 1 auto'} container column style={{maxWidth: 180, width: 180}}>
                                    <Flex item container grow={0} center
                                          style={{
                                              margin: 3,
                                              padding: 5,
                                              backgroundColor: '#f9fafc',
                                              height: 30
                                          }}>
                                        <HeaderTitle style={{textAlign: 'center'}}>{translate('global.plan-date')}</HeaderTitle>
                                    </Flex>
                                    {
                                        !_.isEmpty(slantData) && slantData.planDates.map((planDate, index) => (
                                            <Cell key={`planDateRow-${index}`}>{toDate(planDate)}</Cell>
                                        ))
                                    }
                                    <Flex item container grow={0} center
                                          style={{
                                              margin: 2,
                                              padding: 5,
                                              backgroundColor: '#f9fafc',
                                              height: 30
                                          }}>
                                        <HeaderTitle style={{textAlign: 'center'}}>{translate('global.actual')}</HeaderTitle>
                                    </Flex>
                                </Flex>
                                {
                                    !_.isEmpty(slantData) && slantData.columns.map((column, columnIndex) => (
                                        <TargetMonthColumn key={`column-${columnIndex}`}
                                                           {...{column,
                                                               planDates: slantData.planDates,
                                                               onMouseOver, onMouseOut, onColumnClick,
                                                               isHovered: targetMonth === column.targetMonth
                                                           }}/>
                                    ))
                                }
                            </Flex>
                        </>
                    }
                </Flex>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        SlantView
    )
);
