import {getGeneralViewData, setFilterState} from 'scenes/dashboard/Dashboard.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            generalViewData: state.dashboardReducer.generalViewData,
            selectedProduct: state.dashboardReducer.selectedProduct || {},
            filterState: state.dashboardReducer.filterState,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            getGeneralViewData: () => dispatch(getGeneralViewData()),
            setFilterState: filterState => dispatch(setFilterState(filterState))
        };
    }
};
