import React from 'react';
import Flex from 'components/grid/Flex';
import {Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import _ from 'lodash';

const NumberCell = styled((props) => <Typography {...props} />)(({theme}) => ({
    fontFamily: 'Inter',
    maxWidth: '33%',
    flex: '1 1 auto',
    fontSize: '16px',
    fontWeight: '400',
    color: "#11142D",
    textTransform: 'capitalize'
}));

export default function PlanRow(props) {
    const {row, onMouseOver, onMouseOut, isHovered, onRowClick, isLastColumn, empty, numberFormatter} = props;
    const onMouseOverEvent = empty ? () => {
    } : () => onMouseOver(row.targetMonth);
    const onClickEvent = () => onRowClick(row.targetMonth);
    return (
        empty
            ? <Flex item container center style={{
                margin: 5, padding: 5,
                backgroundColor: "rgba(230, 230, 230, 0.5)",
                border: '2px solid white',
                height: 50, maxHeight: 50, position: 'relative', opacity: 0.7
            }}>n/a</Flex>
            : <Flex item container onMouseOver={onMouseOverEvent} onMouseOut={onMouseOut} onClick={onClickEvent}
                    style={{
                        ...(!isLastColumn ? {
                            paddingRight: 12,
                            margin: '5px -7px 5px 5px'
                        } : {
                            paddingRight: 0,
                            margin: '5px 5px 5px 5px'
                        }),
                        cursor: 'pointer',
                        borderWidth: 2,
                        borderStyle: 'solid',
                        borderTopColor: isHovered ? 'blue' : 'white',
                        borderBottomColor: isHovered ? 'blue' : 'white',
                        borderLeftColor: 'rgba(0,0,0,0)',
                        borderRightColor: isHovered && isLastColumn ? 'blue' : 'rgba(0,0,0,0)',
                    }}>
                <Flex item container center
                      style={{
                          padding: 5,
                          backgroundColor: row.accuracy >= 80
                              ? '#f9fafc'
                              : row.accuracy > 50
                                  ? "#f9e6e2" : "#f3cdd0",
                          height: 50, maxHeight: 50, position: 'relative'
                      }}>
                    <NumberCell style={{paddingLeft: 12}}>{numberFormatter.format(row.sumPlan)}</NumberCell>
                    <NumberCell
                        style={{marginRight: 10}}>{numberFormatter.format(_.round(row.biasPercentage, 0))} %</NumberCell>

                    <div style={{
                        ...(row.biasPercentage > 0 && {bottom: 34}),
                        ...(row.biasPercentage < 0 && {top: 26}),
                        right: 26,
                        backgroundColor: "#808191",
                        height: Math.min(20, 20 * (row.deviationPercentage / 100)),
                        width: 18,
                        position: 'absolute'
                    }}/>
                    <div style={{
                        top: 25,
                        right: 20,
                        backgroundColor: "#808191",
                        height: 2,
                        width: 30,
                        position: 'absolute'
                    }}/>
                    <Flex item/>
                </Flex>
            </Flex>
    );
}
