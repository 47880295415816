import {setFilterState, getQualityCategoriesTable, exportQualityCategoriesData} from 'scenes/dashboard/Dashboard.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            selectedProduct: state.dashboardReducer.selectedProduct || {},
            filterState: state.dashboardReducer.filterState || {},
            qualityCategories: state.dashboardReducer.qualityCategories || []
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            setFilterState: filterState => dispatch(setFilterState(filterState)),
            getQualityCategoriesTable: () => dispatch(getQualityCategoriesTable()),
            exportQualityCategoriesData:(qualityCategoriesExportDto) => dispatch(exportQualityCategoriesData(qualityCategoriesExportDto)),
        };
    }
};
