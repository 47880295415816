import {exportListViewData, getMonthlyTableViewData, setFilterState} from 'scenes/dashboard/Dashboard.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            monthlyTableViewData: state.dashboardReducer.monthlyTableViewData,
            selectedProduct: state.dashboardReducer.selectedProduct || {},
            filterState: state.dashboardReducer.filterState || {}
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            getMonthlyTableViewData: () => dispatch(getMonthlyTableViewData()),
            setFilterState: filterState => dispatch(setFilterState(filterState)),
            exportListViewData:(listViewData) => dispatch(exportListViewData(listViewData)),
        };
    }
};
