import React, {Component} from 'react';
import FilterSidebar from 'scenes/dashboard/components/FilterSidebar';
import {connect} from 'react-redux';
import connector from './MonhtlyTableView.connect';
import {withTranslation} from 'react-i18next';
import Flex from 'components/grid/Flex';
import _ from 'lodash';
import {Button, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import ActualRow from './ActualRow';
import PlanRow from './PlanRow';
import moment from 'moment';

export const HeaderTitle = styled((props) => <Typography {...props} />)(({theme}) => ({
    fontFamily: 'Inter',
    fontSize: '13px',
    fontWeight: '600',
    color: "#B2B3BD",
    textTransform: 'uppercase',
    flex: '1 1 auto'
}));

export class MonthlyTableView extends Component {

    state = {
        targetMonth: '',
        exportDisabled: false
    }

    componentDidMount() {
        this.refresh();
    }

    onMouseOver = targetMonth => {
        this.setState({targetMonth});
    }

    onRowClick = targetMonth => {
        this.props.setFilterState({
            ...this.props.filterState,
            to: moment(targetMonth + '02'),
            from: moment(targetMonth + '02').subtract(7, 'month')
        });
        this.props.changeDashboardTab('MONTH');
    }

    onMouseOut = () => {
        this.setState({targetMonth: ''});
    }

    refresh = () => {
        this.props.getMonthlyTableViewData();
    }

    onExcelExport = () => {
        this.setState({exportDisabled: true});
        const {monthlyTableViewData, filterState} = this.props;
        const {planner, product, productFamily, from, to} = filterState;
        const lags = [];
        Object.keys(monthlyTableViewData.lags).forEach(key => {
            lags.push(monthlyTableViewData.lags[key])
        });

        const data = {
            lags,
            monthlies: monthlyTableViewData.monthlies,
            planner,
            product,
            productFamily,
            from: from.format('MMMM YYYY'),
            to: to.format('MMMM YYYY')
        };
        this.props.exportListViewData(data).then(() => {
            this.setState({exportDisabled: false});
        });

    }

    render() {
        const {monthlyTableViewData, filterState, t: translate, i18n, selectedProduct} = this.props;
        const {targetMonth, exportDisabled} = this.state;
        const {onMouseOver, onMouseOut, onRowClick} = this;
        const numberFormatter = new Intl.NumberFormat(i18n.language === 'de' ? 'de-DE' : 'en-US', {
            maximumFractionDigits: 1
        });
        return (
            <Flex item container column style={{height: '100%'}}>
                <FilterSidebar refresh={this.refresh}/>
                <Flex item container style={{backgroundColor: '#f7f7f7'}} fullWidth column alignItems={'flex-start'}>
                    <Typography style={{fontSize: 18, fontWeight: 'bold', padding: 5, marginLeft: 20, marginTop: 10}}>
                        {selectedProduct.name || ''}
                    </Typography>
                    <Button disabled={exportDisabled} style={{marginLeft: 20, marginBottom: 10, marginTop: 10}} variant="contained" color="primary" onClick={this.onExcelExport}>Excel Export</Button>
                    <Flex item container style={{
                        backgroundColor: 'white',
                        marginLeft: 10,
                        padding: 10,
                        borderRadius: 16,
                        border: '1px solid #E3E6EC'
                    }} grow={0}>
                        <Flex item={'0 1 auto'} container column style={{maxWidth: 180, width: 180}}>
                            <Flex item container grow={0} center
                                  style={{
                                      margin: 5,
                                      marginTop: 44,
                                      padding: 5,
                                      backgroundColor: '#f9fafc',
                                      height: 50
                                  }}>
                                <HeaderTitle style={{marginRight: 10, marginLeft: 10}}>{translate('global.month')}</HeaderTitle>
                                <HeaderTitle>{translate('global.actual')}</HeaderTitle>
                            </Flex>
                            {
                                !_.isEmpty(monthlyTableViewData) && monthlyTableViewData.monthlies.map((month, index) => (
                                    <ActualRow key={`month-${index}`} {...{
                                        month, onMouseOver, onMouseOut, onRowClick, numberFormatter,
                                        isHovered: targetMonth === month.targetMonth
                                    }}/>
                                ))
                            }
                        </Flex>
                        {
                            !_.isEmpty(monthlyTableViewData) && Object.keys(monthlyTableViewData.lags).map((lagKey, columnIndex) => (
                                <Flex key={`lagColumn-${columnIndex}`} item={'0 1 auto'} container column
                                      style={{maxWidth: 240, width: 240}}>
                                    <Flex item container center
                                          style={{margin: 5, padding: 5, backgroundColor: '#f9fafc'}}>
                                        <HeaderTitle
                                            style={{color: '#11142D', flex: 'unset'}}>{translate('global.lag')} {lagKey}</HeaderTitle>
                                    </Flex>
                                    <Flex item container grow={0} center
                                          style={{margin: 5, padding: 5, backgroundColor: '#f9fafc', height: 50}}>
                                        <HeaderTitle style={{marginRight: 10, marginLeft: 10}}>{translate('global.plan')}</HeaderTitle>
                                        <HeaderTitle
                                            style={{marginRight: 10}}>{translate('global.bias')}</HeaderTitle>
                                        <HeaderTitle style={{marginRight: 10}}>{translate('global.error')}</HeaderTitle>
                                    </Flex>
                                    {
                                        !_.isEmpty(monthlyTableViewData.lags[lagKey])
                                        && [...Array(monthlyTableViewData.monthlies.length - monthlyTableViewData.lags[lagKey].length).keys()].map((row, rowIndex) => (
                                            <PlanRow key={`month-${columnIndex}-${rowIndex}`} {...{ empty: true,
                                                row, onMouseOver, onMouseOut, onRowClick, isHovered: false,
                                                isLastColumn: false, numberFormatter
                                            }}/>
                                        ))
                                    }
                                    {
                                        !_.isEmpty(monthlyTableViewData.lags[lagKey])
                                        && monthlyTableViewData.lags[lagKey].map((row, rowIndex) => (
                                            <PlanRow key={`month-${columnIndex}-${rowIndex}`} {...{
                                                row, onMouseOver, onMouseOut, onRowClick, numberFormatter,
                                                isHovered: targetMonth === row.targetMonth,
                                                isLastColumn: Object.keys(monthlyTableViewData.lags).length-1 === columnIndex
                                            }}/>
                                        ))
                                    }
                                </Flex>
                            ))
                        }
                    </Flex>
                </Flex>
            </Flex>
        )
            ;
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
    withTranslation()(
        MonthlyTableView
    )
);
