import React from 'react';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import PaaTextField from 'components/paa-textfield/PaaTextField';

export default function MonthPicker(props) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                views={['year', 'month']}
                openTo="year"
                value={props.value}
                minDate={props.minDate ? new Date(props.minDate) : undefined}
                maxDate={props.maxDate ? new Date(props.maxDate) : undefined}
                onChange={props.onChange}
                renderInput={(params) =>
                    <PaaTextField size={'small'}
                                  style={{...props.style, maxWidth: 200}}
                                  {...params} />
                }
            />
        </LocalizationProvider>
    );
}
