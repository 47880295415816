export const SET_FILTER_STATE = '@dashboard/SET_FILTER_STATE';
export const SET_FILTER_STATE_SUCCESS = '@dashboard/SET_FILTER_STATE_SUCCESS';
export const SET_FILTER_STATE_FAIL = '@dashboard/SET_FILTER_STATE_FAIL';

export const GET_PLANNING_DATA = '@dashboard/GET_PLANNING_DATA';
export const GET_PLANNING_DATA_SUCCESS = '@dashboard/GET_PLANNING_DATA_SUCCESS';
export const GET_PLANNING_DATA_FAIL = '@dashboard/GET_PLANNING_DATA_FAIL';

export const GET_GENERAL_VIEW_DATA = '@dashboard/GET_GENERAL_VIEW_DATA';
export const GET_GENERAL_VIEW_DATA_SUCCESS = '@dashboard/GET_GENERAL_VIEW_DATA_SUCCESS';
export const GET_GENERAL_VIEW_DATA_FAIL = '@dashboard/GET_GENERAL_VIEW_DATA_FAIL';

export const GET_MONTHLY_TABLE_VIEW_DATA = '@dashboard/GET_MONTHLY_TABLE_VIEW_DATA';
export const GET_MONTHLY_TABLE_VIEW_DATA_SUCCESS = '@dashboard/GET_MONTHLY_TABLE_VIEW_DATA_SUCCESS';
export const GET_MONTHLY_TABLE_VIEW_DATA_FAIL = '@dashboard/GET_MONTHLY_TABLE_VIEW_DATA_FAIL';

export const GET_SLANT_TABLE_VIEW_DATA = '@dashboard/GET_SLANT_TABLE_VIEW_DATA';
export const GET_SLANT_TABLE_VIEW_DATA_SUCCESS = '@dashboard/GET_SLANT_TABLE_VIEW_DATA_SUCCESS';
export const GET_SLANT_TABLE_VIEW_DATA_FAIL = '@dashboard/GET_SLANT_TABLE_VIEW_DATA_FAIL';

export const GET_QUALITY_CATEGORIES_TABLE = '@dashboard/GET_QUALITY_CATEGORIES_TABLE';
export const GET_QUALITY_CATEGORIES_TABLE_SUCCESS = '@dashboard/GET_QUALITY_CATEGORIES_TABLE_SUCCESS';
export const GET_QUALITY_CATEGORIES_TABLE_FAIL = '@dashboard/GET_QUALITY_CATEGORIES_TABLE_FAIL';

export const GET_TARGET_MONTH_VIEW_DATA = '@dashboard/GET_TARGET_MONTH_VIEW_DATA';
export const GET_TARGET_MONTH_VIEW_DATA_SUCCESS = '@dashboard/GET_TARGET_MONTH_VIEW_DATA_SUCCESS';
export const GET_TARGET_MONTH_VIEW_DATA_FAIL = '@dashboard/GET_TARGET_MONTH_VIEW_DATA_FAIL';

export const EXPORT_TARGET_MONTH_VIEW_DATA = '@dashboard/EXPORT_TARGET_MONTH_VIEW_DATA';
export const EXPORT_TARGET_MONTH_VIEW_DATA_SUCCESS = '@dashboard/EXPORT_TARGET_MONTH_VIEW_DATA_SUCCESS';
export const EXPORT_TARGET_MONTH_VIEW_DATA_FAIL = '@dashboard/EXPORT_TARGET_MONTH_VIEW_DATA_FAIL';

export const EXPORT_QUALITY_CATEGORIES_DATA = '@dashboard/EXPORT_QUALITY_CATEGORIES_DATA';
export const EXPORT_QUALITY_CATEGORIES_DATA_SUCCESS = '@dashboard/EXPORT_TARGET_QUALITY_CATEGORIES_DATA_SUCCESS';
export const EXPORT_QUALITY_CATEGORIES_DATA_FAIL = '@dashboard/EXPORT_QUALITY_CATEGORIES_DATA_FAIL';

export const EXPORT_SLANT_VIEW = '@dashboard/EXPORT_SLANT_VIEW';
export const EXPORT_SLANT_VIEW_SUCCESS = '@dashboard/EXPORT_SLANT_VIEW_SUCCESS';
export const EXPORT_SLANT_VIEW_FAIL = '@dashboard/EXPORT_SLANT_VIEW_FAIL';

export const EXPORT_LIST_VIEW = '@dashboard/EXPORT_LIST_VIEW';
export const EXPORT_LIST_VIEW_SUCCESS = '@dashboard/EXPORT_TARGET_LIST_VIEW_SUCCESS';
export const EXPORT_LIST_VIEW_FAIL = '@dashboard/EXPORT_LIST_VIEW_FAIL';

export const GET_CONFIGURATION = '@dashboard/GET_CONFIGURATION';
export const GET_CONFIGURATION_SUCCESS = '@dashboard/GET_CONFIGURATION_SUCCESS';
export const GET_CONFIGURATION_FAIL = '@dashboard/GET_CONFIGURATION_FAIL';
