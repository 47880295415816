import React from 'react';
import Flex from 'components/grid/Flex';
import {styled} from '@mui/material/styles';

const HeaderCell = styled((props) => <Flex {...props} />)(({theme}) => ({
    fontFamily: 'Inter',
    margin: '0px 2px',
    maxWidth: '33%',
    flex: '1 1 10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: '400',
    padding: 5,
    borderRadius: 4,
}));

export default function QualityHeader(props) {
    const {translate, qualityCategoryMode, noLag} = props;
    return (
        <Flex item container grow={0} style={{
            ...props.style,
            height: 35, width: '95%', margin: '2px 0px'
        }}>
            <HeaderCell>
                {translate(`dynamic-key.${qualityCategoryMode}`)}
            </HeaderCell>
            <HeaderCell>
                {translate('quality.total-actual')}
            </HeaderCell>
            <HeaderCell>
                {translate('quality.total-plan') }
            </HeaderCell>
            <HeaderCell>
                {translate('quality.accuracy')}
            </HeaderCell>
            <HeaderCell>
                {translate('quality.deviation')}
            </HeaderCell>
            {
                !noLag &&
                <HeaderCell>
                    {translate('global.lag') }
                </HeaderCell>
            }
        </Flex>
    );
}
