import {setFilterState} from 'scenes/dashboard/Dashboard.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            planners: state.dashboardReducer.planners,
            productFamilies: state.dashboardReducer.productFamilies,
            products: state.dashboardReducer.products,
            qualityCategoryModes: state.dashboardReducer.qualityCategoryModes,
            lags: state.dashboardReducer.lags,
            deviations: state.dashboardReducer.deviations,
            productsFromBackend: state.dashboardReducer.productsFromBackend,
            minMaxDate: state.dashboardReducer.minMaxDate,
            filterState: state.dashboardReducer.filterState || {},
            dynamicFilterOptions: state.dashboardReducer.dynamicFilterOptions || [],
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            setFilterState: filterState => dispatch(setFilterState(filterState)),
        };
    }
};
