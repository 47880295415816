import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import {
    concrete,
    transparentBlack03,
    transparentBlack087,
    htmlWhite,
    linkWater,
    persianGreen
} from 'components/colors/Colors';
import {ExitToApp as LogoutIcon} from '@mui/icons-material';
import {
    ClickAwayListener,
    Typography,
    Divider,
    Button
} from '@mui/material';
import {Trans, withTranslation} from "react-i18next";
import HmoAvatar from 'components/avatar/HmoAvatar'
import FeatureSwitch from 'utils/FeatureSwitch';
import LanguageSelector from 'components/language-selector/LanguageSelector';
import {connect} from 'react-redux';
import connector from './Profile.connect';
import {getName} from 'domain/User.model';
import {withStyles} from "@mui/styles";

const styles = theme => ({
    profile: {
        position: 'absolute',
        boxShadow: `0 0 5px 0 ${transparentBlack03}`,
        left: 50,
        bottom: 0,
        color: transparentBlack087,
        backgroundColor: htmlWhite,
        fontSize: 13,
        fontFamily: 'Arial',
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: 0.01071,
        zIndex: 10,
        width: 'auto',
        paddingTop: 5
    },
    profileContent: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: 9,
        backgroundColor: htmlWhite,
        '&:hover': {
            backgroundColor: concrete,
            cursor: 'pointer'
        },
        whiteSpace: 'nowrap'
    },
    avatar: {
        width: 30,
        height: 30,
        margin: 10,
        border: `2px solid ${htmlWhite}`,
        '&:hover': {
            border: `2px solid ${linkWater}`,
            cursor: 'pointer'
        }
    },
    profileAvatar: {
        width: 50,
        height: 50,
        marginBottom: 5
    },
    icon: {
        width: 29,
        height: 18,
        margin: 4
    },
    username: {
        display: 'inline',
        textTransform: 'capitalize',
        fontWeight: 'bold'
    },
    email: {
        display: 'inline',
        whiteSpace: 'nowrap'
    },
    version: {
        color: persianGreen,
        fontSize: 12,
        textAlign: 'center'
    },
    buttonText: {
        textTransform: 'none',
        '&:hover': {
            cursor: 'pointer'
        },
    },
    dropDownArea: {
        display: 'flex',
        flex: '0 1 auto',
        justifyContent: 'space-between'
    }
});


export class Profile extends Component {

    state = {showProfile: false};

    toggleProfile = () => {
        this.setState({showProfile: !this.state.showProfile})
    };

    hideProfile = () => {
        this.setState({showProfile: false})
    };

    render() {
        const {classes, t: translate, user, logout, positionStyle, isSidebarExpanded} = this.props;
        const {showProfile} = this.state;
        return (
            <ClickAwayListener onClickAway={this.hideProfile}>
                <div>
                    <Flex item container alignItems={'center'}>
                        <HmoAvatar {...{user: user}} classes={{avatarRoot: classes.avatar}}
                                   onClick={this.toggleProfile}/>
                        {
                            isSidebarExpanded &&
                            <Button
                                onClick={this.toggleProfile}
                                style={{
                                    fontSize: 14, fontWeight: 'bold',
                                    color: 'black',
                                    textTransform: 'none'
                                }}>
                                <Trans i18nKey={'global.account'}/>
                            </Button>
                        }
                    </Flex>
                    {
                        showProfile &&
                        <Flex container direction={'column'} className={classes.profile} style={positionStyle}>
                            <Flex item container
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  direction={'column'}
                                  style={{padding: 12}}
                            >
                                <HmoAvatar {...{user: user}} classes={{avatarRoot: classes.profileAvatar}}/>
                                <span className={classes.username}>{getName(user)}</span>
                                <span className={classes.email}>{user.email}</span>
                            </Flex>
                            {
                                FeatureSwitch.versionInProfile.active &&
                                <span className={classes.version}>v.{process.env.REACT_APP_VERSION}</span>
                            }
                            <Divider/>
                            <Flex item container
                                  alignItems={"center"}
                                  className={classes.profileContent}
                                  style={{paddingLeft: 9}}
                            >
                                <LanguageSelector/>
                            </Flex>
                            <Divider/>
                            <Flex item container
                                  justifyContent={"flex-start"}
                                  alignItems={"center"}
                                  onClick={logout}
                                  className={classes.profileContent}>
                                <LogoutIcon className={classes.icon}/>
                                <Typography className={classes.buttonText}>
                                    {translate("login.logout")}
                                </Typography>
                            </Flex>
                        </Flex>

                    }
                </div>
            </ClickAwayListener>
        )
    }
}

export default withStyles(styles, {withTheme: true})(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(Profile)));
