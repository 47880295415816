import * as actionTypes from './Dashboard.action.types';
import _ from 'lodash';
import moment from 'moment';
import {toDate} from 'utils/Utils';
import utils from 'utils/Utils';

const initialState = {
    filterState: {
        // planner: 'ALL',
        // productFamily: 'ALL',
        dynamicPropertyInputs: [],
        product: 'ALL',
        from: moment().subtract(2, 'years'),
        to: moment().subtract(1, 'month'),
        isInputSelected: true,
        qualityCategoryMode: 'product_id',
        deviation: 'RED',
        selectedLags: [1,3,5,7]
    },
    minMaxDate: {minDate: null, maxDate: moment()},
    selectedProduct: {},
    dynamicFilterOptions: [],
    products: ['ALL'],
    productsFromBackend: [],
    generalViewData: {},
    monthlyTableViewData: {},
    targetMonthData: [],
    slantData: [],
    qualityCategories: [],
    qualityCategoryModes: ['product'],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_FILTER_STATE: {
            const newState = _.cloneDeep(state);
            newState.filterState = _.get(action, 'data.filterState');
            return newState;
        }
        case actionTypes.GET_CONFIGURATION_SUCCESS: {
            const newState = _.cloneDeep(state);
            const response = action.payload.data;
            const minDate = moment(response.minMaxDate.minDate + '02');
            const maxDate = moment(response.minMaxDate.maxDate + '02');
            newState.minMaxDate = {
                minDate: minDate.format(utils.API_DATE_FORMAT),
                maxDate: maxDate.format(utils.API_DATE_FORMAT)
            };
            newState.filterState.to = moment(newState.filterState.to).isAfter(maxDate) ? maxDate : newState.filterState.to;
            newState.filterState.from = moment(newState.filterState.from).isBefore(minDate) ? minDate : newState.filterState.from;
            return newState;
        }
        case actionTypes.SET_FILTER_STATE_SUCCESS: {
            const newState = _.cloneDeep(state);
            const response = action.payload.data;
            newState.dynamicFilterOptions = response.filterOptionsList;
            newState.qualityCategoryModes = [...response.filterOptionsList.map(option=> option.dynamicKey), 'product_id'];
            newState.products = [...response.productList.filter(i => i.value).map(i => i.value)];
            newState.lags = response.lags;
            newState.deviations = response.deviations;
            newState.productsFromBackend = [...response.productList.filter(i => i.value)];
            newState.selectedProduct = !_.isEmpty(newState.filterState.product) && newState.filterState.product !== 'ALL'
                ? response.productList.find(i=> i.value === newState.filterState.product)
                : '';
            return newState;
        }
        case actionTypes.GET_GENERAL_VIEW_DATA: {
            const newState = _.cloneDeep(state);
            newState.monthlyTableViewData = {};
            return newState;
        }
        case actionTypes.GET_GENERAL_VIEW_DATA_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.generalViewData = mapToLags(action.payload.data);
            return newState;
        }
        case actionTypes.GET_MONTHLY_TABLE_VIEW_DATA: {
            const newState = _.cloneDeep(state);
            newState.generalViewData = {};
            return newState;
        }
        case actionTypes.GET_MONTHLY_TABLE_VIEW_DATA_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.monthlyTableViewData = mapToMonthTable(action.payload.data);
            return newState;
        }
        case actionTypes.GET_TARGET_MONTH_VIEW_DATA_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.targetMonthData = mapToTargetMonthGraph(action.payload.data, newState.filterState.to);
            return newState;
        }
        case actionTypes.GET_SLANT_TABLE_VIEW_DATA_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.slantData = mapToSlantView(action.payload.data);
            return newState;
        }
        case actionTypes.GET_QUALITY_CATEGORIES_TABLE_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.qualityCategories = action.payload.data.rows;
            return newState;
        }
        default:
            return state;
    }
}

function mapToSlantView(slantData) {
    let result = {
        columns: [],
        planDates: []
    };
    result.planDates = _.uniqBy(slantData.rows, 'planDate').map(i => i.planDate);
    result.columns = slantData.rows
        .reduce((acc, current) => {
            const existingColumn = acc.find(i => i.targetMonth === current.targetMonth);
            if (existingColumn) {
                existingColumn.rows.push(current);
            } else {
                acc.push({targetMonth: current.targetMonth, actualCount: current.actualCount, rows: [current]})
            }
            return acc;
        }, [])
        .sort((a, b) => a.targetMonth - b.targetMonth);
    return result;
}

function mapToTargetMonthGraph(targetMonthData, to) {
    const result = [
        moment(to).subtract(7, 'month').format('YYYYMM'),
        moment(to).subtract(5, 'month').format('YYYYMM'),
        moment(to).subtract(3, 'month').format('YYYYMM'),
        moment(to).subtract(1, 'month').format('YYYYMM'),
    ].map(month => {
        const actual = targetMonthData.actualCounts.find(i => i.targetMonth === month);
        const plan = targetMonthData.planCounts.find(i => i.planDate === month);
        return {
            actual: actual ? actual.actualCount : 'n.a.',
            plan: plan ? plan.planCount : 'n.a.',
            date: toDate(month),
        }
    })

    const lastActual = targetMonthData.actualCounts.find(i => i.targetMonth === moment(to).format('YYYYMM'));
    result.push({
        actual: lastActual ? lastActual.actualCount : 'n.a.',
        plan: lastActual ? lastActual.actualCount : 'n.a.',
        date: toDate(moment(to).format('YYYYMM')),
    });
    return result;
}

export function mapToMonthTable(monthlyData) {

    monthlyData.rows = monthlyData.rows.map(m => {

        let deviationCount = m.sumPlan - m.sumActual;
        let biasPercentage = 0;
        let deviationPercentage = 0;
        let accuracy = 0;

        if(m.sumActual === 0) {
            if(m.sumPlan === 0) {
                biasPercentage = 0
                deviationPercentage = 0;
                accuracy = 100;
            } else {
                biasPercentage = 100
                deviationPercentage = 100;
                accuracy = 0;
            }
        } else {
            if(m.sumPlan === 0) {
                biasPercentage = -100;
                deviationPercentage = Math.abs(biasPercentage);
                accuracy = 0;
            } else {
                biasPercentage = (deviationCount * 100) / m.sumActual;
                deviationPercentage = Math.abs(biasPercentage);
                accuracy = 100 - deviationPercentage;
            }
        }

        return {
            ...m,
            deviationCount,
            biasPercentage,
            deviationPercentage,
            accuracy
        };
    });

    const lags = monthlyData.rows.reduce((acc, row) => {
        if (acc[row.lag]) {
            acc[row.lag].push(row)
            return acc;
        } else {
            return {...acc, [row.lag]: [row]}
        }
    }, {})
    const monthlies = monthlyData.rows.reduce((acc, row) => {
        const monthly = acc.find(i => i.targetMonth === row.targetMonth);
        if (!monthly) {
            acc.push({...row})
        }
        return acc;
    }, [])
    return {lags, monthlies};
}

export function mapToLags(generalViewData) {
    return generalViewData.pieData.map(pie => {
        const lag = pie.lag;
        const green = generalViewData.productCategories.green.find(i => i.lag === lag);
        const orange = generalViewData.productCategories.orange.find(i => i.lag === lag);
        const red = generalViewData.productCategories.red.find(i => i.lag === lag);
        const history = generalViewData.historyGraph.filter(i => i.lag === lag);

        return {
            lag,
            averageDeviation: pie.averageDeviation,
            greenProductCount: !_.isEmpty(green) ? green.productCount || 0 : 0,
            orangeProductCount: !_.isEmpty(orange) ? orange.productCount || 0 : 0,
            redProductCount: !_.isEmpty(red) ? red.productCount || 0 : 0,
            history: history.map(i => ({
                'Plan Accuracy': calcAccuracy(i.planCountSum, i.actualCountSum),
                x: toDate(i.targetMonth)
            }))
        }
    })
}

const calcAccuracy = (planCountSum, actualCountSum) => {
    if(actualCountSum === 0) {
        return planCountSum === 0 ? 100 : 0;
    }
    return _.round(100 - (Math.abs((planCountSum - actualCountSum)/actualCountSum) * 100), 0);
}
