import React from 'react';
import Flex from 'components/grid/Flex';
import {styled} from '@mui/material/styles';
import _ from 'lodash';

const Cell = styled((props) => <Flex {...props} />)(({theme}) => ({
    fontFamily: 'Inter',
    margin: '0px 2px',
    maxWidth: '33%',
    flex: '1 1 10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: '400',
    color: "#11142D",
    padding: 5,
    borderRadius: 4,
    backgroundColor: '#f9fafc'
}));

export default function QualityRow(props) {
    const row = props.data.rows[props.index];
    const numberFormatter = props.data.numberFormatter;
    const formatRow = props.data.formatRow;
    const formattedRow = formatRow(row, numberFormatter);
    return (
        <Flex item container grow={0} style={{
            ...props.style,
            height: 52, maxHeight: 52, width: '95%', margin: '2px 0px'
        }}>
            <Cell style={{fontWeight: 'bold'}}>
                {formattedRow.dynamicProperty}
            </Cell>
            <Cell style={{color: 'blue'}}>
                {formattedRow.totalActualCount}
            </Cell>
            <Cell>
                {formattedRow.totalPlanCount}
            </Cell>
            <Cell style={{color: 'grey'}}>
                {formattedRow.totalDeviation}
            </Cell>
            <Cell style={{color: formattedRow.averageDeviationPerc > 50 ? 'red' : formattedRow.averageDeviationPerc > 20 ? 'orange' : 'green'}}>
                {formattedRow.averageDeviationPercStr}
            </Cell>
            {
                !!formattedRow.lag &&
                <Cell style={{color: 'grey'}}>
                    {formattedRow.lag}
                </Cell>
            }
        </Flex>
    );
}
